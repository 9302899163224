<template>
  <div>
    <modal
      v-if="view_student_modal"
      @close="toggleModal()"
      :label="student_name(view_student)"
      blocking
      @submit="
        $router.push('/admin/students/detail/' + view_student.student_id)
      "
      submit_btn_text="View Full Student Profile"
    >
      <template v-slot:header>
        <div class="flex justify-start items-center">
          <div class="secondhead-text mr-3">
            {{ student_name(view_student) }}
          </div>
          <div>
            <div
              class="py-1 px-3 block border border-dark-purple rounded-full text-xl text-dark-purple text-center w-full"
            >
              {{ view_student_acad }}
            </div>
          </div>
        </div>
      </template>
      <template v-slot:content>
        <student-profile-view
          :student="view_student"
          :acad_lvl="view_student_acad"
        />
      </template>
    </modal>
    <modal
      v-if="show_selected_students_modal"
      @close="show_selected_students_modal = !show_selected_students_modal"
      :label="`Students for Promotion ${
        selectedLevel ? `to ${selectedLevel.level}` : ''
      }`"
      submit_btn_text="Clear Selected Students"
      @submit="selectedStudents = []"
      show_cancel
      cancel_btn_text="Close"
      blocking
      medium
    >
      <template #content>
        <dynamic-table
          :records="selectedStudents"
          :fields="tableConfig"
          no-data-msg="No students to display for the current filter"
          hover
          striped
        >
          <template #cell(select)="{ item }">
            <div class="w-full p-2">
              <a
                href="javascript:void(0)"
                @click="toggleStudentSelected([item])"
                title="Remove Student from Selected Students"
                class="text-error-text border border-error-text bg-white rounded px-2 py-1"
              >
                <font-awesome-icon icon="trash"></font-awesome-icon>
              </a>
            </div>
          </template>
          <template #cell(student-name)="{ item }">
            {{ student_name(item) }}
          </template>
          <template #cell(student-year)="{ item }">
            {{
              item.student_status === "ACTIVE"
                ? item.profiles.length > 0
                  ? item.profiles.sort(
                      (a, b) => b.acad_level.value - a.acad_level.value
                    )[0].acad_level.level
                  : "No Level"
                : "No Level"
            }}
          </template>
          <template #cell(student-fieldwork-status)="{ item }">
            {{ item.approved_hours ? item.approved_hours : 0 }}
          </template>
        </dynamic-table>
      </template>
    </modal>
    <div class="header-text pb-3" data-cy="header-admin-student">
      Student Promotion
    </div>
    <div class="box-style mb-5">
      <div class="grid grid-cols-2 gap-5 mb-3">
        <div>
          <div class="mb-2">Grade Level</div>
          <ul class="flex justify-between items-center">
            <li class="w-20">
              <input
                class="sr-only peer"
                type="radio"
                name="at_level"
                id="level_none"
                value="New"
                v-model="filter.acad_lvl"
              />
              <label class="acad_picker" for="level_none">None</label>
            </li>
            <li class="w-20" v-for="(acad_lvl, key) in acad_lvls" :key="key">
              <input
                class="sr-only peer"
                type="radio"
                name="at_level"
                :id="`level_${acad_lvl.value}`"
                :value="acad_lvl.level"
                v-model="filter.acad_lvl"
              />
              <label class="acad_picker" :for="`level_${acad_lvl.value}`">
                {{ acad_lvl.level }}
              </label>
            </li>
          </ul>
        </div>
        <div>
          <div class="mb-2">Term</div>
          <select
            class="w-full rounded border-dark-gray py-1"
            v-model="filter.term"
          >
            <option
              class="h-8"
              v-for="(term, key) in terms"
              :key="key"
              :value="term"
            >
              {{ term.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="profile_box">
        <div class="flex justify-between items-center">
          <div>
            <button class="acad_picker" @click="selectAll(allSelected)">
              {{ allSelected ? "Deselect" : "Select" }} All
            </button>
          </div>
          <div class="flex justify-end items-center">
            <div>
              Promote
              <a
                href="javascript:void(0)"
                class="text-purple-link underline"
                title="View selected students"
                @click="
                  show_selected_students_modal = !show_selected_students_modal
                "
              >
                {{ selectedStudents.length }} selected students
              </a>
              to
            </div>
            <select
              class="rounded border-dark-gray py-1 text-sm mx-2"
              v-model="selectedLevel"
            >
              <option class="h8" :value="null"></option>
              <option
                class="h-8"
                v-for="(acad_lvl, key) in acad_lvls"
                :key="key"
                :value="acad_lvl"
              >
                {{ acad_lvl.level }}
              </option>
              <option class="h-8" value="Graduate">Graduate</option>
            </select>
            <div class="flex justify-end items-center mr-2">
              <div>for selected Term</div>
            </div>
            <button
              @click="submit()"
              class="py-2 px-4 bg-white border-purple border rounded-full text-dark-purple hover:text-white hover:bg-purple disabled:bg-light-gray disabled:text-slate-gray"
              :disabled="selectedStudents.length <= 0 || selectedLevel === null"
            >
              Promote
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="box-style overflow-auto">
      <div v-if="isLoading">
        <LoadingSpinner />
      </div>
      <div v-else>
        <div v-if="students">
          <dynamic-table
            :records="studentList"
            :fields="tableConfig"
            :pagination-page="paginationPage"
            :pagination-total-items="paginationTotalItems"
            :pagination-page-size="itemsPerPage"
            @pageChanged="nextPage($event)"
            no-data-msg="No students to display for selected academic year"
            hover
            striped
            pagination
          >
            <template #cell(select)="{ item }">
              <div class="w-full p-2">
                <input
                  class="rounded"
                  type="checkbox"
                  :id="item.student_id + '-alertradio'"
                  @change="toggleStudentSelected([item])"
                  v-model="item.selected"
                />
                <label class="sr-only" :for="item.student_id + '-alertradio'">
                  alert checkbox for student {{ item.firstname }}
                  {{ item.lastname }}
                </label>
              </div>
            </template>
            <template #cell(student-name)="{ item }">
              <a
                class="text-purple-link cursor-pointer underline"
                href="javascript:void(0)"
                role="button"
                @click="toggleModal(item)"
                >{{ student_name(item) }}</a
              >
            </template>
            <template #cell(student-year)="{ item }">
              {{
                item.student_status === "ACTIVE"
                  ? item.profiles.length > 0
                    ? item.profiles.sort(
                        (a, b) => b.acad_level.value - a.acad_level.value
                      )[0].acad_level.level
                    : "No Level"
                  : "No Level"
              }}
            </template>
            <template #cell(student-fieldwork-status)="{ item }">
              {{ item.approved_hours ? item.approved_hours : 0 }}
            </template>
          </dynamic-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingSpinner from "@/components/reusable-components/LoadingSpinner";
import DynamicTable from "@/components/displays/admin-displays/DynamicTable";
import { get, post } from "@/composables/httpUtil";
import { FormatDate } from "@/composables/date_utils";
import { mapActions, mapGetters } from "vuex";
import Modal from "@/components/modal/Modal";
import StudentProfileView from "@/components/reusable-components/StudentProfileView";

export default {
  name: "StudentRecords",
  components: { StudentProfileView, Modal, DynamicTable, LoadingSpinner },
  data() {
    return {
      loading: true,
      limit: 10,
      page: 1,
      students: [],
      selectedLevel: null,
      selectedStudents: [],

      filter: {
        acad_lvl: "New",
        term: null,
      },

      terms: [],

      isLoading: false,

      allSelected: false,

      paginationTotalItems: 0,
      paginationTotalPages: 0,
      itemsPerPage: 25,
      paginationPage: 1,

      view_student_modal: false,
      show_selected_students_modal: false,
      view_student: null,
      view_student_acad: null,

      tableConfig: [
        { name: "select", display: "" },
        { display: "Name", name: "student-name" },
        { display: "Current Year", name: "student-year" },
        { display: "Fieldwork Status", name: "student-fieldwork-status" },
      ],
    };
  },
  async mounted() {
    this.isLoading = true;
    this.fetchLookupResource()
      .then(
        await this.get("admin/terms").then((terms) => {
          this.terms = terms.data;
          this.filter.term = this.lookup_current_term;
        })
      )
      .then(await this.fetchListOfStudents())
      .finally(() => {
        this.$watch(
          (vm) => vm.filter,
          () => {
            this.isLoading = true;
            this.fetchListOfStudents().finally(() => (this.isLoading = false));
          },
          { immediate: true, deep: true }
        );
        this.isLoading = false;
      });
  },
  methods: {
    get,
    post,
    submit() {
      this.isLoading = true;
      this.post("admin/students/promote", {
        students: this.selectedStudents,
        acad_level: this.selectedLevel,
        term: this.filter.term,
      })
        .then(() => (this.selectedStudents = []))
        .then(() => this.fetchListOfStudents())
        .finally(() => (this.isLoading = false));
    },
    toggleStudentSelected(students) {
      // console.log(students);
      students.forEach((student) => {
        const selectedIdx = this.selectedStudents.findIndex(
          (s) => s.student_id === student.student_id
        );
        if (selectedIdx >= 0) {
          this.selectedStudents.splice(selectedIdx, 1);
        } else {
          this.selectedStudents.push(student);
        }
      });
    },
    selectAll(deselect = false) {
      deselect
        ? (this.selectedStudents = [])
        : this.students.forEach((s) => this.selectedStudents.push(s));
      this.allSelected = !deselect;
    },
    fetchListOfStudents() {
      return get(
        `admin/students/promote?page=${this.paginationPage}&limit=${this.itemsPerPage}&academic_level=${this.filter.acad_lvl}&term_id=${this.filter.term.term_id}`
      )
        .then((results) => {
          this.students = results.data;

          this.paginationTotalItems = results.count;
          this.paginationTotalPages = results.total_pages;
        })
        .catch((error) => {
          console.error("error in getting students", error);
        });
    },
    changeStudentLevel(student) {
      console.log("CHANGE", student.firstname);
    },
    toggleModal(student) {
      if (this.view_student_modal) {
        this.view_student = null;
        this.view_student_acad = null;
      } else {
        this.view_student = student;
        this.view_student_acad =
          student.profiles.length > 0
            ? student.profiles.sort(
                (a, b) => b.acad_level.value - a.acad_level.value
              )[0].acad_level.level
            : "No Level";
      }
      this.view_student_modal = !this.view_student_modal;
    },
    student_name(student) {
      let name = student
        ? `${student.firstname}
            ${student.nickname ? "(" + student.nickname + ")" : ""} ${
            student.lastname
          }`
        : false;
      if (name) {
        name = `${student.prefix ? student.prefix : ""} ${name} ${
          student.suffix ? student.suffix : ""
        }`;
      }
      return name;
    },
    nextPage(paginationInformation) {
      // console.log(paginationInformation);
      this.paginationPage = paginationInformation.newPage;
      this.allSelected = false;
      this.fetchListOfStudents();
    },
    FormatDate,
    ...mapActions(["fetchLookupResource"]),
  },
  computed: {
    ...mapGetters([
      "lookup_student_status_options",
      "lookup_acad_levels",
      "lookup_practice_areas",
      "lookup_current_term",
    ]),
    acad_lvls() {
      return this.lookup_acad_levels.filter((lvl) => lvl.level !== "N/A");
    },
    studentList() {
      return this.students.map((student) => {
        student.selected =
          this.selectedStudents.findIndex(
            (s) => s.student_id === student.student_id
          ) >= 0;
        return student;
      });
    },
  },
};
</script>

<style scoped>
.acad_picker {
  @apply py-1 px-3 block border border-dark-purple rounded-full text-xl text-dark-purple text-center w-full h-8 peer-checked:bg-dark-purple peer-checked:text-white cursor-pointer hover:bg-dark-purple hover:text-white;
}
</style>
